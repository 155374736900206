export const CATEGORIE_PRODUITS_ACTUELS_PAYSAGE = {
  PRAIRIES: {
    libelle: "Prairies",
    couleur: "#D1F18C",
    classeIcone: "icon-prairie",
    classeCouleur: "prairie",
    libelleLong: "Prairie, estives, landes",
  },
  FOURRAGES: {
    libelle: "Cultures fourragères",
    image: "/assets/img/icons/cat/ico_CATEGORIES_viande.svg",
    classeIcone: "icon-fourragere",
    couleur: "#F1CF98",
    classeCouleur: "fourrage",
    libelleLong: "Cultures Fourragères",
  },
  LEGUMINEUSES: {
    libelle: "Légumineuses",
    image: "/assets/img/icons/cat/ico_CATEGORIES_legumes.svg",
    classeIcone: "icon-legumineuses",
    couleur: "#FDD084",
    classeCouleur: "legumineuse",
    libelleLong: "Légumineuses",
  },
  CEREALES: {
    libelle: "Céréales",
    image: "/assets/img/icons/cat/ico_CATEGORIES_cereales.svg",
    classeIcone: "icon-oleagineux",
    couleur: "#FBCDC2",
    classeCouleur: "oleagineux",
    libelleLong: "Céréales, Oléagineux, protéagineux",
  },
  CULTURES_INDUSTRIELLES_ALIMENTAIRES: {
    libelle: "Cultures industrielles",
    image: "/assets/img/icons/cat/ico_CATEGORIES_cereales.svg",
    classeIcone: "icon-cereales2",
    couleur: "#9CE6FA",
    libelleLong:
      "Cultures industrielles alimentaires (pomme de terre, betterave à sucre…)",
    classeCouleur: "pdt",
  },
  FRUITS: {
    libelle: "Fruits",
    image: "/assets/img/icons/cat/ico_CATEGORIES_fruits.svg",
    classeIcone: "icon-ico_CATEGORIES_fruits",
    couleur: "#FB97C7",
    sau_ha: 2944.700000000001,
    libelleLong: "Fruits",
    classeCouleur: "fruitssurface",
  },
  LEGUMES: {
    libelle: "Légumes",
    image: "/assets/img/icons/cat/ico_CATEGORIES_legumes.svg",
    classeIcone: "icon-ico_CATEGORIES_legumes",
    couleur: "#EABDFF",
    sau_ha: 15587.319999999998,
    libelleLong: "Légumes",
    classeCouleur: "legumessurface",
  },
  JACHERES: {
    libelle: "Jachères",
    image: "/assets/img/icons/cat/ico_CATEGORIES_cereales.svg",
    classeIcone: "icon-jachere",
    couleur: "#9CC347",
    sau_ha: 8756.239999999996,
    libelleLong: "Jachères",
    classeCouleur: "fruitssurface",
  },
  CULTURES_INDUSTRIELLES_AUTRES: {
    libelle: "Cultures industrielles Autres",
    image: "/assets/img/icons/cat/ico_CATEGORIES_cereales.svg",
    classeIcone: "icon-ico_CATEGORIES_fruits",
    couleur: "#9CE6FA",
    sau_ha: 34.78000000000001,
    libelleLong: "Autres cultures industrielles",
    classeCouleur: "fruitssurface",
  },
  AUTRES: {
    libelle: "Autres",
    image: "/assets/img/icons/cat/ico_CATEGORIES_cereales.svg",
    couleur: "#62D5F3",
    sau_ha: 5.08,
    libelleLong: "Autres",
    classeCouleur: "fruitssurface",
  },
  BIODIV: {
    couleur: "#38a13f",
    classeIcone: "icon-impact-biodiversite",
    libelle: "Surfaces pour Biodiversité",
    libelle_court: "Surfaces pour Biodiversité",
    libelleLong: "Surfaces pour Biodiversité",
    classeCouleur: "text-vert-bio",
  },
};
